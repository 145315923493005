body {
    background-color: white;
}

.add-pledges {
  font-family: "Speedee Bold" !important;
}

.add-pledges .auth-wrap .auth-col label {
  font-size: 16px;
}

.add-pledges h1.main-ttl {
  font-size: 30px;
}

.pledge-input {
    width: 100% !important;
    padding: 20px;
    font-size: 4em !important;
    height: auto !important;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex; /* Use flexbox for the parent container */
    flex-wrap: wrap; /* Allow wrapping of buttons */
    justify-content: center; /* Center the buttons */
    align-items: center; /* Center the buttons vertically */
  }
  

  .pledge-button {
    display: inline-block !important;
    width: 160px; /* Set a fixed width for the buttons */
    height: 145px; /* Set a fixed height for the buttons */
    padding: 0;
    margin: 10px; /* Space between buttons */
    background-color: #20bf6b !important;
    border: 2px solid #333; /* Black border */
    color: #333; /* Text color */
    font-size: 2rem !important; /* Font size */
    font-weight: bold !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 150px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    font-family: "Speedee Bold" !important;
    border-radius: 4px;
  }

  .add-pledges .stylization button, .stylization input[type=submit] {
    padding: 0;
    vertical-align: middle; /* Vertically center text */
    border-radius: 4px;
  }
  
  .pledge-button:hover {
    background-color: #26de81 !important; /* Darker yellow on hover */
  }

  .custom-input {
    width: 100% !important;
    padding: 20px !important;
    font-size: 4rem !important;
    border: 2px solid #ccc !important;
    border-radius: 5px !important;
    box-sizing: border-box !important;
    display: flex !important; /* Use flexbox for the parent container */
    flex-wrap: wrap !important; /* Allow wrapping of buttons */
    justify-content: center !important; /* Center the buttons */
    align-items: center !important; /* Center the buttons vertically */
  }


  .custom-pledge-button {
    display: inline-block !important;
    width: 450px; /* Set a fixed width for the buttons */
    height: 120px; /* Set a fixed height for the buttons */
    margin: 10px; /* Space between buttons */
    background-color: #20bf6b !important;
    border: 2px solid #333; /* Black border */
    color: #333; /* Text color */
    font-size: 3rem !important; /* Font size */
    font-weight: bolder !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 100px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    border-radius: 4px;
  }
  
  .custom-pledge-button:hover {
    background-color: #26de81 !important; /* Darker yellow on hover */
  }

  .m-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .m-1 {
    margin: 0.5rem !important;
  }
  
  .add-pledges .pledge-buttons-container button {
    font-family: "Speedee Bold" !important;
    font-size: 1.5rem !important;
    height: 130px;
    width: 160px;
    white-space: normal;       /* Allow text to wrap */
    overflow-wrap: break-word; /* Ensure long words break */
    text-align: center;        /* Center-align the text */
    display: flex;             /* Use flexbox to center content */
    justify-content: center;   /* Center horizontally */
    align-items: center;       /* Center vertically */
    padding: 10px;             /* Add padding for better text wrapping */
    border: 5px solid #0fb9b1 !important;
    outline: none;
    border-radius: 4px;
  }
  .add-pledges .pledge-buttons-container button:focus {
    outline: none;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-primary {
    background-color: #0fb9b1 !important;
    border-color: #0fb9b1 !important;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-primary:hover {
    background-color: #2bcbba !important;
    border-color: #2bcbba !important;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-outline-primary {
    background-color: #ebeff4 !important;
    border-color: #0fb9b1 !important;
    color: #55575a !important;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-outline-primary:hover {
    background-color: #0fb9b1 !important;
    color: white !important;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-secondary {
    background-color: #20bf6b !important;
    border-color: #20bf6b !important;
    color: white;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-secondary:hover {
    background-color: #26de81 !important;
    border-color: #26de81 !important;
    color: white;
  }


  .add-pledges .pledge-buttons-container button.btn.btn-outline-secondary {
    background-color: #ebeff4 !important;
    border-color: #20bf6b !important;
    color: #55575a !important;
  }

  .add-pledges .pledge-buttons-container button.btn.btn-outline-secondary:hover {
    background-color: #20bf6b !important;
    color: white !important;
  }

  .header .shop-menu button {
    font-family: "Speedee Bold" !important;
    font-size: 1rem !important;
    height: 50px;
    white-space: normal;       /* Allow text to wrap */
    overflow-wrap: break-word; /* Ensure long words break */
    text-align: center;        /* Center-align the text */
    display: flex;             /* Use flexbox to center content */
    justify-content: center;   /* Center horizontally */
    align-items: center;       /* Center vertically */
    padding: 15px;             /* Add padding for better text wrapping */
    border: 5px solid #f7b731 !important;
    outline: none;             /* Remove focus outline */
    border-radius: 4px !important;
  }

  .header .shop-menu button:focus {
    outline: none;
  }


  .header .shop-menu button.btn.btn-primary {
    background-color: #f7b731 !important;
    border-color: #f7b731 !important;
  }

  .header .shop-menu button.btn.btn-primary:hover {
    background-color: #f7b731 !important;
    border-color: #f7b731 !important;
  }

  .header .shop-menu button.btn.btn-outline-primary {
    background-color: #ebeff4 !important;
    border-color: #fed330 !important;
    color: #55575a !important;
  }

  .header .shop-menu button.btn.btn-outline-primary:hover {
    background-color: #fed330 !important;
    color: white !important;
  }


  .reset.pledge-buttons-container button.btn.btn-primary {
    background-color: #eb3b5a !important;
    border-color: #eb3b5a !important;
  }

  .reset.pledge-buttons-container button.btn.btn-primary:hover {
    background-color: #fc5c65 !important;
    border-color: #fc5c65 !important;
  }

  .reset.pledge-buttons-container button:focus {
    outline: none; /* Remove focus outline */
  }


  .reset.pledge-buttons-container button {
    font-family: "Speedee Bold" !important;
    font-size: 1rem !important;
    height: 50px;
    white-space: normal;       /* Allow text to wrap */
    overflow-wrap: break-word; /* Ensure long words break */
    text-align: center;        /* Center-align the text */
    display: flex;             /* Use flexbox to center content */
    justify-content: center;   /* Center horizontally */
    align-items: center;       /* Center vertically */
    padding: 15px;             /* Add padding for better text wrapping */
    border: 5px solid #f7b731 !important;
    outline: none;             /* Remove focus outline */
    border-radius: 4px;
  }

  .add-pledges .message {
    font-weight: "bold";
    font-size: 1.7rem;
  }

  .add-pledges .message.success {
    color: #20bf6b !important;
  }

  .add-pledges .message.welcome {
    color: #4b6584 !important;
  }

  .add-pledges .message.info {
    color: #f7b731 !important; 
  }

  .message-well {
    margin: 0 0 10px;
    overflow: hidden;
    line-height: 1.7;
    height: 160px; /* Set the height as needed */
    padding: 20px; /* Add padding inside the well */
    background-color: #ffffff; /* White background color */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
  }
  

  .header .social-icons {
    padding-top: 0 !important;
  }


  .custom-info-button {
    display: inline-block !important;
    width: 450px; /* Set a fixed width for the buttons */
    height: 120px; /* Set a fixed height for the buttons */
    margin: 10px; /* Space between buttons */
    background-color: #fa8231 !important;
    border: 2px solid #333; /* Black border */
    color: #333; /* Text color */
    font-size: 3rem !important; /* Font size */
    font-weight: bolder !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 100px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  
  .custom-info-button:hover {
    background-color: #fd9644 !important; /* Darker yellow on hover */
  }

  .auth-submit .custom-submit-button {
    display: inline-block !important;
    width: 450px; /* Set a fixed width for the buttons */
    height: 100px; /* Set a fixed height for the buttons */
    margin-top: 15px; /* Space between buttons */
    background-color: #eb3b5a !important;
    color: white; /* Text color */
    font-size: 3rem !important; /* Font size */
    font-weight: bolder !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 100px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .auth-submit .custom-submit-button:hover {
    background-color: #fc5c65 !important; /* Darker yellow on hover */
  }

  .auth-submit .custom-submit-button.submit-button {
    float:right;
    background-color: #f7b731 !important;
  }

  .auth-submit .custom-submit-button.submit-button:hover {
    float:right;
    background-color: #fed330 !important;
  }
  
  



  .pledge-buttons-container .total {
    float: left;
    font-size: 2rem;
    color: #fc5c65;
  }

  .pledge-input {
    width: 100% !important;
    padding: 20px;
    font-size: 4em !important;
    height: auto !important;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex; /* Use flexbox for the parent container */
    flex-wrap: wrap; /* Allow wrapping of buttons */
    justify-content: center; /* Center the buttons */
    align-items: center; /* Center the buttons vertically */
  }