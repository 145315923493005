.order-status-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .order-status-table th,
  .order-status-table td {
    padding: 12px;
    text-align: center;
    height: 100px; /* Set the height of each row */
    vertical-align: middle; /* Align content vertically in the center */
  }
  
  .order-status-table .order-status-image img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  
  .order-status-table thead {
    background-color: #f7f7f7 !important;
    font-weight: bold;
  }
  

  .order-status-table tbody tr:hover {
    background-color: #ddd;
  }
  
  .order-status-table th,
.order-status-table td {
  border: none;
  background-color: transparent !important;
}

.order-status-table th {
  font-size: 2rem !important; /* Set the font size for table headers */
  font-weight: bold; /* Ensure font weight for headers */
  color: black;
}

/* Remove background color from table headers */
.order-status-table thead {
  background-color: transparent !important;
}

/* Remove background color from table rows */
.order-status-table tbody tr {
  font-size: 1.5rem;
  background-color: transparent;
  border-bottom: 1px solid #ddd; /* Line between rows */
}

.order-status-table {
  border: none !important;
}
